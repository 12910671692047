import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex } from "theme-ui";
import Footer from "../../components/footer";
import { PrivacyPolicyContentComponent } from "../../components/privacy-policy-content/privacy-policy-content";

import "./privacy-policy.css";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <>
      <div className="privacy-policy">
        <Flex sx={{ flexDirection: "column" }}>
          <span className="navigation__back-to-homepage-wrapper">
            <Button onClick={() => navigate("/")} variant="primary">
              Back to homepage
            </Button>
          </span>
          <PrivacyPolicyContentComponent />
        </Flex>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
