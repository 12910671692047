import { Theme } from "theme-ui";

const makeTheme = <T extends Theme>(t: T) => t;

export const theme = makeTheme({
  breakpoints: ["680px", "1024px", "1280px", "1600px"],
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
  zIndices: {
    backgroundImages: -100,
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    transparent: "transparent",

    gray100: "#31333F",
    blue100: "#3149AD",

    primary100: "#E40046",
    primary200: "#E6134F",
    primary300: "#881135",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "Montserrat",
  },
  fontWeights: {
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  links: {
    primary: {
      fontWeight: 500,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  buttons: {
    primary: {
      position: "relative",
      userSelect: "none",
      display: "flex",
      transition: "all 0.3s",
      font: "normal normal 500 18px/22px Montserrat",
      alignItems: "center",
      justifyContent: "center",
      bg: "transparent",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "white",
      borderRadius: "37px",
      padding: "14px 42px",
      "&:hover": {
        opacity: 0.85,
        cursor: "pointer",
      },
      "&:active": {
        top: "1px",
        cursor: "pointer",
        opacity: 1,
      },
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
    secondary: {
      position: "relative",
      userSelect: "none",
      display: "flex",
      transition: "all 0.3s",
      font: "normal normal 500 18px/22px Montserrat",
      alignItems: "center",
      justifyContent: "center",
      bg: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "white",
      borderRadius: "37px",
      padding: "14px 52px",
      color: "primary100",
      "&:hover": {
        opacity: 0.85,
        cursor: "pointer",
      },
      "&:active": {
        top: "1px",
        cursor: "pointer",
        opacity: 1,
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.8,
        top: "0px",
      },
    },
    transparent: {
      position: "relative",
      padding: 0,
      margin: 0,
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      transition: "all 0.3s",
      "&:hover": {
        cursor: "pointer",
        opacity: 0.9,
      },
      "&:active": {
        top: "1px",
        cursor: "pointer",
        opacity: 1,
      },
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
  },
  variants: {
    appContainer: {
      width: "100%",
      minHeight: "100vh",
      flexDirection: "column",
    },
  },
  shadows: {
    small: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    large: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  },
});

export type ExactTheme = typeof theme;
