import * as React from "react";

function GooglePlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="Group_3886" data-name="Group 3886" xmlns="http://www.w3.org/2000/svg" width={168.546} height={49.976} viewBox="0 0 168.546 49.976" {...props}>
      <g id="Group_3734" data-name="Group 3734" transform="translate(0 0)">
        <g id="Group_3733" data-name="Group 3733">
          <g id="Group_3732" data-name="Group 3732">
            <g id="Group_3731" data-name="Group 3731">
              <g id="Group_3730" data-name="Group 3730">
                <path id="Path_11769" data-name="Path 11769" d="M142.747,100.528c-.4,0-.786-.005-1.181-.014a16.662,16.662,0,0,1-2.442-.213,7.7,7.7,0,0,1-2.165-.716,7.078,7.078,0,0,1-1.825-1.329,6.956,6.956,0,0,1-1.334-1.824,7.513,7.513,0,0,1-.709-2.165,16.2,16.2,0,0,1-.217-2.45c-.008-.276-.02-1.194-.02-1.194V60.444s.012-.9.02-1.169a16.135,16.135,0,0,1,.216-2.446,7.545,7.545,0,0,1,.711-2.171,7.016,7.016,0,0,1,1.326-1.826,7.26,7.26,0,0,1,1.832-1.335,7.61,7.61,0,0,1,2.161-.711,16.337,16.337,0,0,1,2.45-.214l1.178-.016H291.5l1.193.016a16.179,16.179,0,0,1,2.429.213,7.763,7.763,0,0,1,2.183.716,7.207,7.207,0,0,1,1.817,1.326,7.3,7.3,0,0,1,1.338,1.836,7.566,7.566,0,0,1,.7,2.154,17.089,17.089,0,0,1,.227,2.465c0,.37,0,.767,0,1.163.01.49.01.956.01,1.427V89.213c0,.476,0,.938-.01,1.406,0,.425,0,.814,0,1.214a16.788,16.788,0,0,1-.223,2.422,7.554,7.554,0,0,1-.705,2.182,7.184,7.184,0,0,1-1.327,1.809,7.09,7.09,0,0,1-1.828,1.337,7.674,7.674,0,0,1-2.179.719,16.483,16.483,0,0,1-2.443.213c-.382.009-.783.014-1.172.014l-1.416,0Z" transform="translate(-132.854 -50.555)" fill="#fff"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Group_3735" data-name="Group 3735" transform="translate(11.809 6.172)">
        <path id="Path_11770" data-name="Path 11770" d="M182.245,73.2h-7.984v2.369h5.662c-.28,3.322-3.044,4.74-5.653,4.74a6.328,6.328,0,0,1,.008-12.656,6.142,6.142,0,0,1,4.268,1.713l1.659-1.718a8.435,8.435,0,0,0-6.012-2.37,8.7,8.7,0,1,0,.125,17.405c4.66,0,8.07-3.192,8.07-7.912a7.153,7.153,0,0,0-.144-1.572Z" transform="translate(-134.681 -52.215)" fillRule="evenodd"/>
        <path id="Path_11771" data-name="Path 11771" d="M184.846,72.223a3.4,3.4,0,1,1-3.2,3.385,3.216,3.216,0,0,1,3.2-3.385Zm-.033-2.2a5.594,5.594,0,1,0,5.613,5.613,5.527,5.527,0,0,0-5.613-5.613Z" transform="translate(-130.462 -50.759)" fillRule="evenodd"/>
        <path id="Path_11772" data-name="Path 11772" d="M194.309,72.223a3.4,3.4,0,1,1-3.2,3.385,3.217,3.217,0,0,1,3.2-3.385Zm-.033-2.2a5.594,5.594,0,1,0,5.614,5.613,5.527,5.527,0,0,0-5.614-5.613Z" transform="translate(-127.562 -50.759)" fillRule="evenodd"/>
        <path id="Path_11773" data-name="Path 11773" d="M203.705,72.225a3.163,3.163,0,0,1,2.988,3.409,3.127,3.127,0,0,1-3.021,3.389,3.178,3.178,0,0,1-3.1-3.368,3.224,3.224,0,0,1,3.128-3.43Zm-.218-2.194a5.6,5.6,0,0,0-.052,11.19,3.7,3.7,0,0,0,3.069-1.359v1.1a2.807,2.807,0,0,1-2.941,3.086A3.1,3.1,0,0,1,200.7,82.06l-2.15.9a5.382,5.382,0,0,0,5.031,3.294c2.991,0,5.269-1.883,5.269-5.832V70.368H206.5v.947a3.957,3.957,0,0,0-3.017-1.284Z" transform="translate(-124.661 -50.758)" fillRule="evenodd"/>
        <path id="Path_11774" data-name="Path 11774" d="M216.013,72.173a2.23,2.23,0,0,1,2.069,1.194l-5.009,2.094a3.107,3.107,0,0,1,2.94-3.288Zm-.1-2.152a5.29,5.29,0,0,0-5.218,5.587,5.41,5.41,0,0,0,5.49,5.614,5.678,5.678,0,0,0,4.688-2.455L218.94,77.48a3.131,3.131,0,0,1-2.741,1.54,2.882,2.882,0,0,1-2.744-1.7l7.5-3.113-.389-.912a5.067,5.067,0,0,0-4.652-3.278Z" transform="translate(-120.805 -50.761)" fillRule="evenodd"/>
        <rect id="Rectangle_1835" data-name="Rectangle 1835" width="2.464" height="16.489" transform="translate(86.069 13.639)"/>
        <rect id="Rectangle_1836" data-name="Rectangle 1836" width="2.464" height="16.489" transform="translate(119.104 13.639)"/>
        <rect id="Rectangle_1837" data-name="Rectangle 1837" width="2.464" height="16.489" transform="translate(106.038 13.639)"/>
        <path id="Path_11775" data-name="Path 11775" d="M223.056,65.718h5.96a5.269,5.269,0,0,1,5.252,5.253h0a5.269,5.269,0,0,1-5.252,5.253h-5.96V65.718Zm2.463,2.464v5.579h3.5a2.789,2.789,0,0,0,0-5.579Z" transform="translate(-117.018 -52.08)" fillRule="evenodd"/>
        <path id="Path_11776" data-name="Path 11776" d="M249.5,86.256l7.134-16.235h-2.691l-7.134,16.235Z" transform="translate(-109.738 -50.761)" fillRule="evenodd"/>
        <path id="Path_11777" data-name="Path 11777" d="M250.767,82.54l-5.5-12.519h2.691l3.794,8.634h-.984Z" transform="translate(-110.211 -50.761)" fillRule="evenodd"/>
        <path id="Path_11778" data-name="Path 11778" d="M244.5,81.069h2.464v-5.93c.385-6.116-7.369-7.555-10.3-3.146l1.982,1.331c1.436-2.508,5.529-1.931,5.8.756a10.937,10.937,0,0,0-3.774-.439,3.982,3.982,0,0,0-3.776,3.367,4.082,4.082,0,0,0,.843,3.18,4.658,4.658,0,0,0,4.625.989,4.239,4.239,0,0,0,2.141-1.438v1.33Zm0-4.766a3.708,3.708,0,0,1-2.727,2.725c-2.849.776-3.558-3.853.677-3.217A10.555,10.555,0,0,1,244.5,76.3Z" transform="translate(-112.848 -50.941)" fillRule="evenodd"/>
        <path id="Path_11779" data-name="Path 11779" d="M173.46,58.885h-3.633v1.078H172.4a2.384,2.384,0,0,1-2.572,2.157,2.88,2.88,0,0,1,0-5.759,2.8,2.8,0,0,1,1.943.779l.755-.781a3.84,3.84,0,0,0-2.737-1.079,3.961,3.961,0,1,0,.057,7.921,3.487,3.487,0,0,0,3.673-3.6,3.244,3.244,0,0,0-.065-.715Z" transform="translate(-134.565 -55.279)" fillRule="evenodd"/>
        <rect id="Rectangle_1838" data-name="Rectangle 1838" width="1.121" height="7.504" transform="translate(53.746 0.264)"/>
        <path id="Path_11780" data-name="Path 11780" d="M178.656,62.985h1.122V56.6h1.841V55.481h-4.8V56.6h1.84Z" transform="translate(-131.19 -55.217)" fillRule="evenodd"/>
        <path id="Path_11781" data-name="Path 11781" d="M172.619,62.985h4.3V61.863h-3.18V59.794h2.8V58.673h-2.8V56.6h3.18V55.481h-4.3Z" transform="translate(-132.476 -55.217)" fillRule="evenodd"/>
        <path id="Path_11782" data-name="Path 11782" d="M186.429,62.985h1.121V56.6h1.841V55.481h-4.8V56.6h1.841Z" transform="translate(-128.808 -55.217)" fillRule="evenodd"/>
        <path id="Path_11783" data-name="Path 11783" d="M194.463,62.12a2.88,2.88,0,1,1,2.81-2.87,2.833,2.833,0,0,1-2.81,2.87Zm0-6.841a3.961,3.961,0,1,0,3.991,3.951A4.027,4.027,0,0,0,194.463,55.279Z" transform="translate(-127.004 -55.279)" fillRule="evenodd"/>
        <path id="Path_11784" data-name="Path 11784" d="M197.439,62.985h1.121V57.519l3.6,5.466h1.119v-7.5H202.16v5.463l-3.6-5.463h-1.121Z" transform="translate(-124.869 -55.217)" fillRule="evenodd"/>
        <path id="Path_11785" data-name="Path 11785" d="M143.62,87.9a2.8,2.8,0,0,0,.336-.155l15.41-8.435-3.653-3.648L143.62,87.9Zm16.428-8.964-3.811-3.8,3.807-3.854,5.243,2.861c1.291.7.631,1.585,0,1.93l-5.239,2.868Zm-.686-8.03-15.406-8.4a1.554,1.554,0,0,0-.537-.17L155.709,74.6l3.653-3.7ZM142.515,62.48l12.67,12.649L142.576,87.891a1.592,1.592,0,0,1-.568-1.394c-.007-1.254-.107-21.156-.115-22.649A1.463,1.463,0,0,1,142.515,62.48Z" transform="translate(-141.893 -53.119)" fillRule="evenodd"/>
      </g>
    </svg>
  );
}

export default GooglePlay;
