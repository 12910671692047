import styled from "@emotion/styled";
import { Box, Flex } from "theme-ui";
import NewsletterImage from "../../assets/images/NewsletterImage.png";

export const NewsletterSections = styled(Flex)``;

NewsletterSections.defaultProps = {
  sx: {
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${NewsletterImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    aspectRatio: "1470/730",
    width: "100%",
    flexDirection: "column",
    paddingInline: "43px",
    paddingTop: ["42px", "42px", 0],
    paddingBottom: ["84px", "84px", 0],
  },
};

export const NewsletterTitle = styled(Box)`
  letter-spacing: -1px;
  opacity: 1;
`;

NewsletterTitle.defaultProps = {
  sx: {
    textAlign: ["center", "center", "center"],
    font: [
      "normal normal 700 28px/32px Montserrat;",
      "normal normal 700 34px/44px Montserrat;",
      "normal normal 700 40px/50px Montserrat;",
    ],
    color: ["white"],
  },
};

export const NewsletterDescription = styled(Box)`
  letter-spacing: 0px;
  opacity: 1;
  text-wrap: wrap;
`;

NewsletterDescription.defaultProps = {
  sx: {
    color: ["white"],
    textAlign: ["center", "center", "center"],
    font: [
      "normal normal 200 14px/20px Montserrat;",
      "normal normal 300 14px/20px Montserrat;",
      "font: normal normal normal 22px/24px Montserrat;",
    ],
  },
};
