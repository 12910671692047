import React from "react";
import Lottie from 'react-lottie';
import * as animationData from './../../../assets/animations/connecting-circles.json';

export default function ConnectingCircles() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        // rendererSettings: {
        //   preserveAspectRatio: 'xMidYMid slice'
        // }
    };

    return (
        <Lottie
            style={{ maxWidth: "100%" }}
            options={defaultOptions}
            width={341}
        />
    )
}