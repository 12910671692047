import React from "react";
import LandingAppImage1 from "./../../assets/images/LandingAppImage1.png";
import LandingAppImage2 from "./../../assets/images/LandingAppImage2.png";
import LandingAppImage3 from "./../../assets/images/LandingAppImage3.png";
import LandingAppImage4 from "./../../assets/images/LandingAppImage4.png";
import { LandingAppImageWrapper } from "./styled";

export default function LandingAppImage(props: React.HTMLAttributes<HTMLElement>) {
  return (
    <LandingAppImageWrapper>
      <img alt="IWI dating app match found" src={LandingAppImage4} {...props} />
      <img alt="IWI dating app searching for matches" src={LandingAppImage3} {...props} />
      <img alt="IWI dating app psychological profiling" src={LandingAppImage2} {...props} />
      <img alt="IWI dating app science of dating" src={LandingAppImage1} {...props} />
    </LandingAppImageWrapper>
  );
}
