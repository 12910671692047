import { useForm, ValidationError } from "@formspree/react";
import React, { useState } from "react";
import { Button, Flex, Link } from "theme-ui";
import TextInput from "../ui/text-input/TextInput";
import { NewsletterDescription, NewsletterSections, NewsletterTitle } from "./styled";

const EMAIL_REGEX = /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function Newsletter() {
  const [email, setEmail] = useState("");

  const [state, handleSubmit] = useForm("xnqlylwn");

  if (state.succeeded) {
    return (
      <NewsletterSections>
        <NewsletterTitle>Thanks for joining!</NewsletterTitle>
      </NewsletterSections>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <NewsletterSections>
        <Flex sx={{ flexDirection: "column", gap: "20px", maxWidth: ["480px"] }}>
          <NewsletterTitle>
            Sign up for Newsletter, promotions & more.
          </NewsletterTitle>

          <Flex sx={{ flexDirection: "column", gap: "20px", width: ["100%", "80%"], alignSelf: "center" }}>
            <Flex sx={{ mt: ["48px"], height: "60px" }}>
              <TextInput
                id="email"
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter your e-mail address"
              />
            </Flex>
            <ValidationError prefix="Email" field="email" errors={state.errors} />

            <Button
              disabled={!email || !EMAIL_REGEX.test(email)}
              type="submit"
              sx={{
                height: "60px",
                font: "normal normal bold 20px/22px Montserrat;"
              }}
              variant="secondary"
            >
              Sign up
            </Button>
          </Flex>
          <Flex sx={{ paddingTop: "64px", maxWidth: "100%" }}>
            <Flex>
              <NewsletterDescription>
                By subscribing you agree to receive IWI newsletters via e-mail. For further information, please consult
                the{" "}
                <Link href="/privacy-policy" variant="primary">
                  Privacy Policy.
                </Link>
              </NewsletterDescription>
            </Flex>
          </Flex>
        </Flex>
      </NewsletterSections>
    </form>
  );
}
