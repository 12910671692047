import * as React from "react";

function B5(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Group 1769"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 330 206.148"
      {...props}
    >
      <defs>
        <clipPath id="b5">
          <path data-name="Rectangle 1720" fill="#fff" d="M0 0H330V206.148H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1768" clipPath="url(#b5)" fill="#fff">
        <path
          data-name="Path 4281"
          d="M46 206.148a45.965 45.965 0 01-46-45.96V45.964A45.933 45.933 0 0148.762.09c49.534 3.118 83.5 12.343 100.964 27.418 21.639 18.68 21.639 35.933 21.639 41.6 0 14.385-7.483 27.193-14.1 34.051 6.612 6.858 14.1 19.666 14.1 34.051 0 5.669 0 22.922-21.639 41.6-11.561 9.981-38.381 22.806-100.5 27.217a45.243 45.243 0 01-3.227.115M45.846 25.58a20.36 20.36 0 00-20.262 20.384v114.223a20.382 20.382 0 0021.831 20.327c41.754-2.965 73.751-10.84 85.593-21.063 12.773-11.028 12.773-18.884 12.773-22.239 0-6.463-4.143-13.512-7.084-16.454l-.242-.241-7.646 6.977c-10.791 9.656-22.853 19.084-41.063 19.084a43.548 43.548 0 01-43.5-43.16v-.182-.077-.076-.183a43.548 43.548 0 0143.5-43.159c18.21 0 30.271 9.428 41.063 19.084l.095.085 7.552 6.893.241-.24c2.942-2.942 7.085-9.992 7.085-16.455 0-3.355 0-11.21-12.773-22.237-12.583-10.861-43.875-18.606-85.854-21.249q-.656-.041-1.309-.041m25.978 77.581A17.937 17.937 0 0089.746 121c8.456 0 14.47-4.04 23.962-12.528l5.815-5.308-5.816-5.308c-9.491-8.487-15.5-12.527-23.961-12.527a17.936 17.936 0 00-17.922 17.835"
        />
        <path
          data-name="Path 4282"
          d="M316.038 203.835a68.883 68.883 0 01-68.8-68.805 13.217 13.217 0 0126.435 0 42.37 42.37 0 0084.74 0c0-19.008-6.218-30.449-20.792-38.256-5.313-2.847-20.636-2.192-31.826-1.714-20.675.883-40.208 1.718-51.694-9.288a25.81 25.81 0 01-7.832-19.149c0-1.356.008-3.225.016-5.41.031-7.646.083-20.44-.169-26.754a28.625 28.625 0 018.3-20.692 30.99 30.99 0 0122.186-9.589c9.834 0 85.116.869 88.316.905a13.218 13.218 0 01-.305 26.434c-.783-.009-78.328-.9-88.01-.9a4.762 4.762 0 00-4.063 2.929c.27 6.926.219 19.464.185 27.778-.008 2.142-.016 3.974-.016 5.3 0 .1 0 .193.007.27.8.452 3.219 1.48 9.285 1.946 6.523.5 14.732.15 22.671-.19 17.352-.742 33.744-1.442 45.437 4.821 23.055 12.35 34.744 33.062 34.744 61.558a68.883 68.883 0 01-68.8 68.805M272.519 33.6z"
          transform="translate(-54.843 -.931)"
        />
      </g>
    </svg>
  );
}

export default B5;
