import * as React from "react";

function Live(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={164} height={97} viewBox="0 0 164 97" {...props}>
      <text
        id="Live"
        transform="translate(82 77)"
        fill="#fff"
        fontSize={80}
        fontFamily="Montserrat-Bold, Montserrat"
        fontWeight={700}
        letterSpacing="-0.025em"
      >
        <tspan x={-81} y={0}>
          {"Live"}
        </tspan>
      </text>
    </svg>
  
  );
}

export default Live;
