import styled from "@emotion/styled";
import { Box, Flex, Link } from "theme-ui";

export const FooterSection = styled(Flex)`
  background-color: ${(props) => props.theme.colors.primary100};
`;

FooterSection.defaultProps = {
  sx: {
    pt: ["48px", "48px", "126px"],
    pb: ["24px", "24px", "48px"],
  },
};

export const FotterSectionContent = styled(Flex)`
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary100};
`;

FotterSectionContent.defaultProps = {
  sx: {
    pl: ["28px", "48px", "250px"],
    pr: ["28px", "48px", "250px"],
  },
};

export const FooterSectionTitle = styled(Box)`
  letter-spacing: -1px;
  opacity: 1;
`;

FooterSectionTitle.defaultProps = {
  sx: {
    textAlign: ["center", "center", "center"],
    font: [
      "normal normal 700 26px/32px Montserrat;",
      "normal normal 700 34px/44px Montserrat;",
      "normal normal 700 40px/54px Montserrat;",
    ],
    color: ["white"],
    marginTop: ["22px", "22px", 0]
  },
};

export const FooterCopyrightText = styled(Box)`
  text-align: center;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.white};
  opacity: 1;
`;

FooterCopyrightText.defaultProps = {
  sx: {
    font: [
      "normal normal normal 11px/13px Montserrat",
      "normal normal normal 10px/13px Montserrat",
      "normal normal normal 14px/18px Montserrat",
    ],
  },
};

export const FooterLink = styled(Link)`
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

FooterLink.defaultProps = {
  sx: {
    font: [
      "normal normal bold 14px/34px Montserrat",
      "normal normal bold 14px/34px Montserrat",
      "normal normal bold 14px/18px Montserrat",
    ],
  },
};
