import React from "react";
// import PropTypes from "prop-types";

export default function YoutubeVideo(props: React.HTMLAttributes<HTMLElement>) {
    return (
        <iframe
            width={"100%"}
            height={658}
            src={`https://www.youtube.com/embed/rd78HEzxU6w`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            {...props}
        />
    );
};

// YoutubeVideo.propTypes = {
//   embedId: PropTypes.string.isRequired
// };
