import { useTheme } from "@emotion/react";
import React, { RefObject, useEffect, useRef, useState, useCallback } from "react";
import { Box, Flex, Button, Paragraph } from "theme-ui";
import { Facebook, Hamburger, Instagram, Linkedin, Twitter } from "../../assets/icons";
import {
  AppleStore,
  B5,
  Circles,
  GooglePlay,
  KickStarter as KickstarterImage,
  NavBackground,
  Sketch,
} from "../../assets/images";

import LiveSectionImageClubbing from "../../assets/images/LiveSectionImageClubbing.gif";
import LiveSectionFindCrystal from "../../assets/images/LiveSectionFindCrystal.gif";
import LiveSectionFindWoman from "../../assets/images/LiveSectionFindWoman.gif";
import LiveSectionMobile from "../../assets/images/LiveSectionMobile.png";
import EventsImageGirlsPartying from "../../assets/images/EventsImageGirlsPartying.gif";

import YoutubeVideo from "./../../components/video";

import { IWILogo, Live as LiveImage } from "../../assets/images";

import PsychologyImage from "../../assets/images/PsychologyImage.gif";

import {
  Container,
  LandingLogoAndAppStoresSection,
  LandingSection,
  LandingAppSection,
  LandingAppSectionTitle,
  LandingAppSectionSubtitle,
  AppNavigationNavText,
  LandingCampaignSection,
  LandingCampaignSectionTitle,
  DatingAppSection,
  DatingAppSectionTitle,
  DatingAppSectionSubtitle,
  PsychologySection,
  PsychologySectionTitle,
  PsychologySectionSubtitle,
  LiveSectionImage,
  LiveSection,
  LiveSectionLogoAndMegaTitle,
  LiveSectionTitle,
  LiveSectionText,
  BoxedContent,
  VideoSection,
  VideoSectionText,
  EventsSection,
  EventsSectionTitle,
  EventsSectionText,
  EventsSectionTextAppendix,
  AppNavigation,
  SplitBackgroundColor,
} from "../../components/landing";

import Sidebar from "../../components/sidebar";
import { useWindowSize } from "react-use";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";
import LandingAppImage from "../../components/app-images";
import ConnectingCircles from "../../components/ui/connecting-circles";
import FaceSketch from "../../components/ui/face-sketch";

export default function LandingPage() {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<React.MutableRefObject<HTMLDivElement | null>>();
  const [sections, setSections] = useState<RefObject<HTMLDivElement>[]>([]);
  const [hasActiveSectionBeenSetOnInit, setHasActiveSectionBeenSetOnInit] = useState<boolean>(false);

  const homeRef = useRef<HTMLDivElement | null>(null);

  const aboutRef = useRef<HTMLDivElement | null>(null);

  const liveRef = useRef<HTMLDivElement | null>(null);

  const eventsRef = useRef<HTMLDivElement | null>(null);

  const size = useWindowSize();
  
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.forEach((section) => {
      if (!section?.current) {
        return;
      }

      const sectionOffsetTop = section.current.offsetTop;
      const sectionHeight = section.current.offsetHeight;

      if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
        newActiveSection = section;
      }
    });

    if (newActiveSection) {
      setActiveSection(newActiveSection);
    }
  }, [sections]);
  
  useEffect(() => {
    if (sections.length && !hasActiveSectionBeenSetOnInit) {
      handleScroll();
      setHasActiveSectionBeenSetOnInit(true);
    }
  }, [handleScroll, hasActiveSectionBeenSetOnInit, sections]);

  useEffect(() => {
    setSections([
      homeRef,
      aboutRef,
      liveRef,
      eventsRef
    ]);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const [, tabletBreakpoint] = theme.breakpoints.map((br) => +br.replace("px", ""));

    if (size.width > tabletBreakpoint) {
      setMenuOpen(false);
    }
  }, [size, theme.breakpoints]);


  return (
    <Container>
      <AppNavigation>
        <BoxedContent>
          <Flex sx={{ width: "100%" }}>
            <Flex sx={{ width: "50%", flexDirection: "column", alignItems: "center", display: ["none", "none", "flex"] }}>
              <Flex sx={{ width: "100%", maxWidth: "396px", justifyContent: "space-between" }}>
                <AppNavigationNavText onClick={() => homeRef.current?.scrollIntoView({ behavior: "smooth" })} className={activeSection?.current === homeRef.current ? 'active' : ''}>
                  Home
                </AppNavigationNavText>
                <AppNavigationNavText onClick={() => aboutRef.current?.scrollIntoView({ behavior: "smooth" })} className={activeSection?.current === aboutRef.current ? 'active' : ''}>
                  About
                </AppNavigationNavText>
                <AppNavigationNavText onClick={() => liveRef.current?.scrollIntoView({ behavior: "smooth" })} className={activeSection?.current === liveRef.current ? 'active' : ''}>
                  Live
                </AppNavigationNavText>
                <AppNavigationNavText onClick={() => eventsRef.current?.scrollIntoView({ behavior: "smooth" })} className={activeSection?.current === eventsRef.current ? 'active' : ''}>
                  Community
                </AppNavigationNavText>
              </Flex>
            </Flex>
            <Flex sx={{ width: "50%", justifyContent: "center", display: ["none", "none", "flex"] }}>
              <Flex sx={{ width: "100%", maxWidth: "225px", justifyContent: "space-between" }}>
                <Button
                  variant="transparent"
                  onClick={() => window.open("https://www.facebook.com/joiniwi/", "_blank")}
                >
                  <Facebook width={"25px"} height={"25px"}></Facebook>
                </Button>
                <Button
                  variant="transparent"
                  onClick={() => window.open("https://www.instagram.com/iwiapp/", "_blank")}
                >
                  <Instagram width={"25px"} height={"25px"}></Instagram>
                </Button>
                <Button variant="transparent" onClick={() => window.open("https://twitter.com/iwiapp", "_blank")}>
                  <Twitter width={"25px"} height={"25px"}></Twitter>
                </Button>

                <Button
                  variant="transparent"
                  onClick={() => window.open("https://www.linkedin.com/company/iwiapp/", "_blank")}
                >
                  <Linkedin width={"29.57px"} height={"29.57px"}></Linkedin>
                </Button>
              </Flex>
            </Flex>
            {/* Mobile size navigation */}
            <Flex
              sx={{ display: ["block", "block", "none"], marginLeft: "auto", paddingRight: "25px" }}
            >
              <Button variant="transparent" onClick={() => setMenuOpen(true)}>
                <Hamburger />
              </Button>
            </Flex>
            <Sidebar
              onHomeClick={() => homeRef?.current?.scrollIntoView({ behavior: "smooth" })}
              onAboutClick={() => aboutRef?.current?.scrollIntoView({ behavior: "smooth" })}
              onLiveClick={() => liveRef?.current?.scrollIntoView({ behavior: "smooth" })}
              onEventsClick={() => eventsRef?.current?.scrollIntoView({ behavior: "smooth" })}
              isOpen={menuOpen}
              setMenuOpen={() => setMenuOpen(false)}
            />
          </Flex>
        </BoxedContent>
      </AppNavigation>

      <div ref={homeRef}>
        <LandingSection>
          <BoxedContent sx={{ flexDirection: ["column", "column", "row"] }}>
            <LandingLogoAndAppStoresSection>
              <Flex
                sx={{
                  flexDirection: "column",
                  px: ["28px", "48px", 0],
                  pt: ["72px", "128px", 0],
                  alignItems: [null, "center"],
                  position: ["relative", "relative", "unset"],
                }}
              >
                <Flex sx={{
                  mt: ["0", "0", "119px"],
                  alignSelf: "center",
                  width: ["140px", "210px"],
                  height: ["80px", "120px"]
                }}>
                  <IWILogo color={theme.colors.white} />
                </Flex>
                <Box sx={{ mt: ["46px"] }}>
                  <LandingAppSectionTitle as="h1">
                    Join IWI
                  </LandingAppSectionTitle>
                </Box>
                <Box sx={{ mt: ["24px"] }}>
                  <LandingAppSectionSubtitle>
                    IWI app uses modern psychology to help you build relationships with people you will easily click with.
                  </LandingAppSectionSubtitle>
                </Box>
                <Flex sx={{ mt: ["80px", "120px", "86px"], position: "relative", justifyContent: "center" }}>
                  <Flex
                    sx={{
                      px: [0, 0, "6px"],
                      gap: ["12px", "15px"],
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{ zIndex: 99 }}
                      variant="transparent"
                      onClick={() => window.open("https://apps.apple.com/hr/app/iwi/id1594025785", "_blank")}
                    >
                      <Flex sx={{ display: ["block", "block", "none"] }}>
                        <AppleStore width={"145px"} height="50px" filter="invert(100%)" />
                      </Flex>
                      <Flex sx={{ display: ["none", "none", "block"] }}>
                        <AppleStore />
                      </Flex>
                    </Button>
                    <Button
                      sx={{ zIndex: 99 }}
                      variant="transparent"
                      onClick={() => window.open("https://play.google.com/store/apps/details?id=com.iwi", "_blank")}
                    >
                      <Flex sx={{ display: ["block", "block", "none"] }}>
                        <GooglePlay width={"155px"} height="50px" filter="invert(100%)" />
                      </Flex>
                      <Flex sx={{ display: ["none", "none", "block"] }}>
                        <GooglePlay />
                      </Flex>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </LandingLogoAndAppStoresSection>
            <LandingAppSection>
              <Flex sx={{ width: "100%", justifyContent: "center", display: ["flex", "flex", "none"] }}>
                <Flex sx={{ width: "100%", maxWidth: "225px", justifyContent: "space-between" }}>
                  <Button
                    variant="transparent"
                    onClick={() => window.open("https://www.facebook.com/joiniwi/", "_blank")}
                  >
                    <Facebook width={"25px"} height={"25px"}></Facebook>
                  </Button>
                  <Button
                    variant="transparent"
                    onClick={() => window.open("https://www.instagram.com/iwiapp/", "_blank")}
                  >
                    <Instagram width={"25px"} height={"25px"}></Instagram>
                  </Button>
                  <Button variant="transparent" onClick={() => window.open("https://twitter.com/iwiapp", "_blank")}>
                    <Twitter width={"25px"} height={"25px"}></Twitter>
                  </Button>

                  <Button
                    variant="transparent"
                    onClick={() => window.open("https://www.linkedin.com/company/iwiapp/", "_blank")}
                  >
                    <Linkedin width={"29.57px"} height={"29.57px"}></Linkedin>
                  </Button>
                </Flex>
              </Flex>
              <Flex sx={{ width: "100%", height: "100%" }}>
                <LandingAppImage />
              </Flex>
            </LandingAppSection>
          </BoxedContent>
        </LandingSection>
      </div>

      <div ref={aboutRef}>
        <DatingAppSection>
          <BoxedContent sx={{ flexDirection: "column" }}>
            <Flex sx={{ flex: 1, flexDirection: ["column", "column", "row"], alignItems: "center" }}>
              <Flex
                sx={{ flexDirection: "column", gap: ["12px", "12px", "40px"], maxWidth: ["unset", "unset", "50%"] }}
              >
                <DatingAppSectionTitle as="h2">Welcome to a new era of personalised dating apps</DatingAppSectionTitle>
                <DatingAppSectionSubtitle>
                  Have you ever wondered if there was a space where your true personality gives you the edge over others? 
                  Meet IWI — a new sort of dating app that <strong>connects compatible personalities</strong> in a very personalised manner.
                </DatingAppSectionSubtitle>
              </Flex>
              <Flex sx={{ justifyContent: "center", width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                  <ConnectingCircles />
                </Box>
              </Flex>
            </Flex>

            <Flex sx={{ flex: 1, flexDirection: ["column-reverse", "column-reverse", "row"], alignItems: "center" }}>
              <Flex sx={{ justifyContent: "center", mt: 0, width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                  <FaceSketch />
                </Box>
              </Flex>
              <Flex sx={{
                flexDirection: "column",
                gap: ["12px", "12px", "40px"],
                maxWidth: ["unset", "unset", "50%"],
                mt: ["48px", "48px", 0]
              }}>
                <DatingAppSectionTitle as="h2">It’s all about personality</DatingAppSectionTitle>
                <DatingAppSectionSubtitle>
                  We made IWI for people who seek to connect with others based on more than just looks. 
                  Simply put, IWI matches people <strong>based on their personality types.</strong> 
                  You don’t need to hide behind an idealised profile to find a compatible partner. 
                  If you really want to meet compatible people, show your true personality in the IWI sphere.
                </DatingAppSectionSubtitle>
              </Flex>
            </Flex>
          </BoxedContent>
        </DatingAppSection>
      </div>

      <Box>
        <PsychologySection>
          <BoxedContent sx={{ flexDirection: "column" }}>
            <Box
              sx={{
                flex: 1,
                maxWidth: "600px",
                maxHeight: "600px",
                aspectRatio: "1 / 1",
                margin: "0 auto 0 auto",
              }}
            >
              <img alt="" src={PsychologyImage} width="100%" height={"100%"} />
            </Box>
            <Flex sx={{ flexDirection: "column" }}>
              <Flex
                sx={{
                  flex: 1,
                  mt: ["68px", "68px", "126px"],
                  flexDirection: ["column-reverse", "column-reverse", "row"],
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Flex sx={{ order: [2, 2, 1], justifyContent: ["center"] }}>
                  <Flex sx={{ flex: 1, maxWidth: ["163px", "unset"], maxHeight: ["102px", "unset"] }}>
                    <B5 />
                  </Flex>
                </Flex>
                <Flex sx={{
                  flex: 1,
                  flexDirection: ["column"],
                  order: [1, 1, 1],
                  maxWidth: [null, null, "546px"],
                  marginTop: ["50px", "50px", 0]
                }}>
                  <Flex>
                    <PsychologySectionTitle as="h2">
                      Psychology-based matching algorithm
                    </PsychologySectionTitle>
                  </Flex>
                  <Box sx={{ mt: ["25px", "25px", "48px"] }}>
                    <PsychologySectionSubtitle>
                      We use a psychology model called <b>Big Five</b> to better understand each user. Answering a dozen
                      fun questions helps our algorithm to be more precise in matching you with people who appreciate,
                      love, and live by the same things as you. And you know the suggestions delivered to you are the
                      people you might click with.
                    </PsychologySectionSubtitle>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </BoxedContent>
        </PsychologySection>
      </Box>

      <div ref={liveRef}>
        <LiveSection>
          <SplitBackgroundColor backgroundcolor1={theme.colors.blue100} backgroundcolor2={theme.colors.white} padding={["40px 20px", "40px 20px", "75px 0"]}>
            <LiveSectionImage>
              <Flex sx={{ flexDirection: "row", position: "relative" }}>
                <Flex sx={{ width: "100%", aspectRatio: "941 / 501" }}>
                  <img alt="IWI live in clubs" src={LiveSectionImageClubbing} width={"100%"} height={"100%"} />
                </Flex>
                <LiveSectionLogoAndMegaTitle sx={{ display: ["none", "none", "flex"], bottom: "" }}>
                  <IWILogo color={"white"} width={"114px"} height={"65px"} />
                  <LiveImage />
                </LiveSectionLogoAndMegaTitle>
                <LiveSectionLogoAndMegaTitle sx={{ display: ["flex", "flex", "none"], bottom: "40px" }}>
                  <IWILogo color={"white"} width={"60px"} height={"34px"} />
                  <LiveImage width={"92px"} height={"55px"}/>
                </LiveSectionLogoAndMegaTitle>
              </Flex>
            </LiveSectionImage>
          </SplitBackgroundColor>
          <Box sx={{ justifyContent: "center", padding: ["0 30px", "0 30px", 0] }}>
            <LiveSectionTitle as="h2" style={{ marginTop: "0px"}}>
              New AR functionalities coming soon
            </LiveSectionTitle>
            <LiveSectionText>
              IWI app has been in the market since the spring of 2022 and now we are working on implementing advanced augmented reality technology to create <strong>IWI Live.</strong> 
              It is the app feature that will make the dating world a lot more fun, engaging, and successful. 
              <br />In the nutshell, IWI Live is advanced technology meets old-school face-to-face encounters.
            </LiveSectionText>
          </Box>
          <Box padding={["0 20px 30px 20px", "0 20px 30px 20px", "75px 0"]}>
            <LiveSectionImage>
              <Flex sx={{ flexDirection: "row", position: "relative" }}>
                <Flex sx={{ width: "100%", aspectRatio: "941 / 501" }}>
                  <img alt="IWI live find crystal" src={LiveSectionFindCrystal} width={"100%"} height={"100%"} />
                </Flex>
              </Flex>
            </LiveSectionImage>
          </Box>
          <Box sx={{ justifyContent: "center", padding: ["0 30px", "0 30px", 0] }}>
            <LiveSectionTitle as="h2">
              How IWI Live works
            </LiveSectionTitle>
            <LiveSectionText style={{ marginBottom: "0" }}>
              When you have IWI Live activated, the app keeps looking for your personality compatibles in places where you’re ‌at. 
              If they’re found, the app notifies you, as well as them. After you match, you can decide if you want to meet up in person. 
            </LiveSectionText>
            <LiveSectionText>
              IWI’s augmented reality capabilities, which are powered by the <strong>ConjureKit SDK</strong>, offer a novel experience and extra security when using a dating app. 
              Before meeting in person, you can invite your potential date into the IWI’s augmented reality metaverse first. 
              This <strong>social AR experience</strong> is not an augmented reality you might have encountered before. 
              With almost instant calibration, IWI Live is faster and more user friendly than anything you have experienced before.
            </LiveSectionText>
          </Box>
          <Box padding={["0 20px 30px 20px", "0 20px 30px 20px", "75px 0"]}>
            <LiveSectionImage>
              <Flex sx={{ flexDirection: "row", position: "relative" }}>
                <Flex sx={{ width: "100%", aspectRatio: "941 / 501" }}>
                  <img alt="IWI live find crystal" src={LiveSectionFindWoman} width={"100%"} height={"100%"} />
                </Flex>
              </Flex>
            </LiveSectionImage>
          </Box>
          <Box sx={{ justifyContent: "center", padding: ["0 30px", "0 30px", 0] }}>
            <LiveSectionText style={{ margin: "0 auto 0 auto" }}>
              With a few clicks, you can spawn avatars and animations or leave messages into the shared space and communicate with your match on a new level. 
              Then you decide if you want to <strong>meet in person</strong> and can even pick the meeting spot right from the virtual space.
            </LiveSectionText>
          </Box>
        </LiveSection>
      </div>

      <VideoSection>
        <SplitBackgroundColor backgroundcolor1={theme.colors.white} backgroundcolor2={theme.colors.primary100}>
          <BoxedContent sx={{
            justifyContent: "center",
            position: "relative",
            width: "100%",
            padding: "80px 150px",
            display: ["none", "none", "block"]
          }}>
            <YoutubeVideo style={{ width: "100%" }} />
          </BoxedContent>
          <BoxedContent sx={{
            justifyContent: "center",
            position: "relative",
            width: "100%",
            padding: "0 20px",
            display: ["block", "block", "none"]
          }}>
            <YoutubeVideo style={{ width: "100%", height: "180px" }} />
          </BoxedContent>
        </SplitBackgroundColor>
        <VideoSectionText>
          <Paragraph sx={{
            justifyContent: "center",
            width: "100%",
            maxWidth: "846px",
            margin: "auto"
          }}>
              We are developing the most innovative functionality in the dating world that will — supported by IWI’s unique psychological profiling — bring a revolution in the dating community. 
              <strong> Watch video, find out more.</strong>
          </Paragraph>
        </VideoSectionText>
      </VideoSection>

      <div ref={eventsRef}>
        <EventsSection>
          <BoxedContent>
            <Flex sx={{
              width: "100%",
              padding: ["0", "0", "0 150px"],
              flexDirection: ["column", "column", "row"]
            }}>
              <Flex sx={{ padding: ["40px 20px 30px 20px", "40px 20px 30px 20px", "0"], width: ["100%", "100%", "50%"] }}>
                <Flex sx={{ flex: "1", aspectRatio: "1/1" }}>
                  <img alt="iwi girls party" src={EventsImageGirlsPartying} width="100%" height="100%" />
                </Flex>
              </Flex>
              <Flex sx={{
                flex: "1",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marignTop: ["30px", "30px", "0"]
              }}>
                <EventsSectionTitle as="h2">
                  Join IWI Events
                </EventsSectionTitle>
                <EventsSectionText>
                  In the past six months, IWI parties have become the staple of Zagreb’s night life. 
                  Join us on the dancefloor and see what the fuss is all about. 
                  This is the stage where to show your best moves.
                </EventsSectionText>
                <EventsSectionTextAppendix>
                  New events soon!
                </EventsSectionTextAppendix>
              </Flex>
            </Flex>
          </BoxedContent>
        </EventsSection>
      </div>

      <Newsletter />

      <Footer />
    </Container>
  );
}
