import * as React from "react";

function Hamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26.455} height={25} viewBox="0 0 26.455 25" {...props}>
      <g data-name="Group 1287" fill="#fff">
        <path
          data-name="Path 656"
          d="M41.661 42.651h-23.02a1.718 1.718 0 110-3.436H41.66a1.718 1.718 0 010 3.436z"
          transform="translate(-16.923 -17.651)"
        />
        <path
          data-name="Path 657"
          d="M41.661 31.869h-23.02a1.718 1.718 0 110-3.436H41.66a1.718 1.718 0 010 3.436z"
          transform="translate(-16.923 -17.651)"
        />
        <path
          data-name="Path 658"
          d="M41.661 21.086h-23.02a1.718 1.718 0 110-3.435H41.66a1.718 1.718 0 010 3.435z"
          transform="translate(-16.923 -17.651)"
        />
      </g>
    </svg>
  );
}

export default Hamburger;
