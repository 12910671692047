import * as React from "react";

function IWILogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 210.535 120" {...props}>
      <path
        data-name="IWI_logo"
        d="M358.7 373.188a12.343 12.343 0 00-16 6.973c-20.216 51.432-38.012 60.894-46.82 61.956-7.052.849-13.4-2.925-17.872-6.733l6.545-7.171.081-.09c9.289-10.38 18.357-21.977 18.357-39.459a41.725 41.725 0 10-83.45 0c0 17.482 9.067 29.077 18.354 39.455l6.62 7.254c-.464.394-.948.788-1.452 1.177-5.615 4.33-11.136 6.2-16.41 5.567-8.808-1.062-26.6-10.524-46.82-61.956a12.343 12.343 0 10-22.975 9.031c9.9 25.2 20.759 44.459 32.261 57.247 10.794 12 22.509 18.8 34.818 20.216a42.019 42.019 0 004.8.276c13.8 0 24.958-6.87 32.4-13.342l.128.14.127-.139c7.445 6.471 18.6 13.342 32.4 13.342a42.076 42.076 0 004.8-.276c12.309-1.413 24.023-8.214 34.818-20.216 11.5-12.788 22.357-32.049 32.261-57.247a12.344 12.344 0 00-6.971-16.005zm-97.436-1.572a17.063 17.063 0 0117.039 17.049c0 6.437-1.8 11.517-12.029 22.954l-5.01 5.489-5.015-5.5c-10.222-11.431-12.024-16.511-12.024-22.947a17.063 17.063 0 0117.042-17.045z"
        transform="translate(-156 -346.93)"
        fill="currentColor"
      />
    </svg>
  );
}

export default IWILogo;
