import * as React from "react";

function Twitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Group 1774"
      xmlns="http://www.w3.org/2000/svg"
      width={35.686}
      height={35.686}
      viewBox="0 0 35.686 35.686"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path data-name="Rectangle 1722" fill="none" d="M0 0H35.686V35.686H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1773" clipPath="url(#a)">
        <path
          data-name="Path 4287"
          d="M31.225 35.686H4.461A4.461 4.461 0 010 31.225V4.461A4.461 4.461 0 014.461 0h26.764a4.461 4.461 0 014.461 4.461v26.764a4.461 4.461 0 01-4.461 4.461m-17.52-8.78a12.931 12.931 0 0013.02-13.02c0-.2 0-.4-.013-.592a9.311 9.311 0 002.283-2.369 9.133 9.133 0 01-2.628.72 4.591 4.591 0 002.012-2.531 9.171 9.171 0 01-2.906 1.111 4.58 4.58 0 00-7.8 4.173 12.991 12.991 0 01-9.43-4.78 4.58 4.58 0 001.417 6.109 4.541 4.541 0 01-2.072-.572v.058a4.578 4.578 0 003.67 4.487 4.569 4.569 0 01-2.067.078 4.581 4.581 0 004.275 3.178 9.182 9.182 0 01-5.683 1.959 9.307 9.307 0 01-1.092-.063 12.955 12.955 0 007.014 2.055"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default Twitter;
