import styled from "@emotion/styled";

export const NonInteractiveContainer = styled.div`
  z-index: 99;
  position: fixed;
  inset: 0;
`;

export const SidebarContainer = styled.div<{ isOpen: boolean }>`
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  z-index: 999;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: fixed;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  height: 502px;
  padding: 48px;
  animation: fadeIn 0.2s;

  background: ${(props) => props.theme.colors.gray100};
`;

export const SidebarItem = styled.div`
  text-align: left;
  font: normal normal bold 24px/60px Montserrat;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.white};
  opacity: 1;
  padding-top: 12px;
  padding-bottom: 12px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`;
