import { css, Global } from "@emotion/react";
import { Box, Button, Flex } from "theme-ui";
import { Hamburger } from "../../assets/icons";
import { SidebarContainer, SidebarItem } from "./styled";

interface SideBarProps {
  setMenuOpen: (value: boolean) => void;
  isOpen: boolean;
  onHomeClick: () => void;
  onAboutClick: () => void;
  onLiveClick: () => void;
  onEventsClick: () => void;
}

export default function Sidebar({
  setMenuOpen,
  isOpen,
  onHomeClick,
  onAboutClick,
  onLiveClick,
  onEventsClick,
}: SideBarProps) {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Global
        styles={
          isOpen
            ? css`
                body {
                  overflow: hidden;
                }
              `
            : css``
        }
      />
      <Flex sx={{ display: ["block", "block", "none"], position: "absolute", right: "25px", top: "25px" }}>
        <Button variant="transparent" onClick={() => setMenuOpen(true)}>
          <Hamburger />
        </Button>
      </Flex>
      <Box>
        <Button variant="transparent" onClick={onHomeClick}>
          <SidebarItem>Home</SidebarItem>
        </Button>
        <Button variant="transparent" onClick={onAboutClick}>
          <SidebarItem>About</SidebarItem>
        </Button>
        <Button variant="transparent" onClick={onLiveClick}>
          <SidebarItem>Live</SidebarItem>
        </Button>
        <Button variant="transparent" onClick={onEventsClick}>
          <SidebarItem>Community</SidebarItem>
        </Button>
      </Box>
    </SidebarContainer>
  );
}
