import * as React from "react";

function Instagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Group 862"
      xmlns="http://www.w3.org/2000/svg"
      width={35.686}
      height={35.686}
      viewBox="0 0 35.686 35.686"
      {...props}
    >
      <path
        data-name="Path 437"
        d="M-477.782 317.631c4.764 0 5.329.018 7.21.1a9.873 9.873 0 013.313.614 5.529 5.529 0 012.052 1.335 5.528 5.528 0 011.335 2.052 9.872 9.872 0 01.614 3.313c.086 1.881.1 2.446.1 7.21s-.018 5.329-.1 7.21a9.871 9.871 0 01-.614 3.313 5.528 5.528 0 01-1.335 2.052 5.529 5.529 0 01-2.052 1.335 9.872 9.872 0 01-3.313.614c-1.881.086-2.445.1-7.21.1s-5.329-.018-7.21-.1a9.87 9.87 0 01-3.313-.614 5.53 5.53 0 01-2.052-1.335 5.529 5.529 0 01-1.335-2.052 9.876 9.876 0 01-.614-3.313c-.086-1.881-.1-2.446-.1-7.21s.018-5.329.1-7.21a9.876 9.876 0 01.614-3.313 5.528 5.528 0 011.335-2.052 5.53 5.53 0 012.052-1.335 9.871 9.871 0 013.313-.614c1.881-.086 2.446-.1 7.21-.1m0-3.215c-4.846 0-5.453.02-7.357.107a13.091 13.091 0 00-4.331.829 8.748 8.748 0 00-3.161 2.058 8.747 8.747 0 00-2.058 3.16 13.1 13.1 0 00-.829 4.331c-.087 1.9-.107 2.511-.107 7.357s.021 5.453.107 7.357a13.1 13.1 0 00.829 4.331 8.747 8.747 0 002.058 3.16 8.748 8.748 0 003.161 2.058 13.1 13.1 0 004.331.829c1.9.087 2.511.107 7.357.107s5.454-.021 7.357-.107a13.1 13.1 0 004.331-.829 8.746 8.746 0 003.16-2.058 8.746 8.746 0 002.058-3.16 13.1 13.1 0 00.829-4.331c.087-1.9.107-2.511.107-7.357s-.02-5.453-.107-7.357a13.1 13.1 0 00-.829-4.331 8.746 8.746 0 00-2.058-3.16 8.745 8.745 0 00-3.16-2.058 13.092 13.092 0 00-4.331-.829c-1.9-.087-2.511-.107-7.357-.107z"
        transform="translate(495.625 -314.416)"
        fill="#fff"
      />
      <path
        data-name="Path 438"
        d="M-364.372 436.972a8.7 8.7 0 00-8.7 8.7 8.7 8.7 0 008.7 8.7 8.7 8.7 0 008.7-8.7 8.7 8.7 0 00-8.7-8.7zm0 14.341a5.645 5.645 0 01-5.645-5.645 5.645 5.645 0 015.645-5.645 5.645 5.645 0 015.645 5.645 5.645 5.645 0 01-5.645 5.645z"
        transform="translate(382.215 -427.826)"
        fill="#fff"
      />
      <ellipse
        data-name="Ellipse 279"
        cx={2.256}
        cy={2.256}
        rx={2.256}
        ry={2.256}
        transform="translate(25.042 6.132)"
        fill="#fff"
      />
    </svg>
  );
}

export default Instagram;
