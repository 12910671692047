import styled from "@emotion/styled";
import { Flex, Box, Paragraph, Heading } from "theme-ui";


export const Container = styled(Flex)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const BoxedContent = styled(Flex)`
  margin: auto;
  max-width: 1470px;
  width: 100%;
`

export const SplitBackgroundColor = styled(Flex)`
  width: 100%;
  background: linear-gradient(to bottom, ${(props: { backgroundcolor1: string, backgroundcolor2: string }) => props.backgroundcolor1} 50%, ${(props) => props.backgroundcolor2} 50%);
`;

export const AppNavigation = styled(Flex)`
  background: ${(props) => props.theme.colors.primary100};
  box-shadow: 0px 0px 30px #0000004E;
  height: 90px;
  padding: 24px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;

export const AppNavigationNavText = styled(Paragraph)`
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font: normal normal 500 18px/42px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  user-select: none;
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &.active {
    &:before {
      content: "";
      border-radius: 100%;
      width: 10px;
      height: 10px;
      background: ${(props) => props.theme.colors.white};
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const LandingSection = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.colors.primary100};
  padding-top: 90px;
`;

LandingSection.defaultProps = {
  sx: {
    flexDirection: ["column", "column", "row"],
  },
};

export const LandingLogoAndAppStoresSection = styled(Flex)`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

LandingLogoAndAppStoresSection.defaultProps = {
  sx: {
    paddingBottom: ["52px", "52px", "120px"]
  }
};

export const LandingAppSectionTitle = styled(Heading)`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
`;

LandingAppSectionTitle.defaultProps = {
  sx: {
    font: ["normal normal 600 40px/40px Montserrat;", "normal normal 600 34px/44px Montserrat;"],
  },
};

export const LandingAppSectionSubtitle = styled(Paragraph)`
  color: ${(props) => props.theme.colors.white};
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  letter-spacing: 0px;
  max-width: 536px;
`;

LandingAppSectionSubtitle.defaultProps = {
  sx: {
    font: ["normal normal 400 16px/22px Montserrat", "normal normal 400 30px/42px Montserrat"],
  },
};

export const LandingAppSection = styled(Flex)`
  flex: 1;
  aspect-ratio: 1;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
`;

export const LandingCampaignSection = styled(Flex)`
  flex: 1;
  background-color: ${(props) => props.theme.colors.gray100};
  flex-wrap: wrap;
`;

LandingCampaignSection.defaultProps = {
  sx: {
    px: ["28px", "40px", "240px"],
    py: ["42px", "42px", "28px"],
    flexDirection: ["column", "column", "row"],
    justifyContent: ["unset", "unset", "space-between"],
    alignItems: ["unset", "unset", "center"],
    gap: ["48px", "48px", 0],
  },
};

export const LandingCampaignSectionTitle = styled(Box)`
  font: normal normal 600 24px/60px Montserrat;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.white};
  opacity: 1;
`;

export const DatingAppSection = styled(Flex)`
  background-color: ${(props) => props.theme.colors.white};
`;

DatingAppSection.defaultProps = {
  sx: {
    pt: ["0", "0", "142px"],
    pb: ["0", "0", "160px"],
    pl: ["28px", "48px", "240px"],
    pr: ["28px", "48px", "198px"],
  },
};

export const DatingAppSectionTitle = styled(Heading)`
  letter-spacing: -1px;
  color: ${(props) => props.theme.colors.black};
  opacity: 1;
  text-align: left;
`;

DatingAppSectionTitle.defaultProps = {
  sx: {
    font: ["normal normal 800 28px/32px Montserrat;", "normal normal 600 40px/44px Montserrat;"],
  },
};

export const DatingAppSectionSubtitle = styled(Paragraph)`
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.black};
  opacity: 1;
  text-align: left;
`;

DatingAppSectionSubtitle.defaultProps = {
  sx: {
    font: ["normal normal 400 14px/20px Montserrat;", "normal normal 400 22px/32px Montserrat;"],
    marginTop: ["25px"]
  },
};

export const PsychologySection = styled(Flex)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    background: linear-gradient(to bottom, ${(props) => props.theme.colors.white} 10%, ${(props) => props.theme.colors.blue100} 10%, ${(props) => props.theme.colors.blue100} 40%, ${(props) => props.theme.colors.blue100} 40%);
  };
  background: linear-gradient(to bottom, ${(props) => props.theme.colors.white} 5%, ${(props) => props.theme.colors.blue100} 5%, ${(props) => props.theme.colors.blue100} 45%, ${(props) => props.theme.colors.blue100} 45%);
`;

PsychologySection.defaultProps = {
  sx: {
    pl: ["28px", "48px", "150px"],
    pr: ["28px", "48px", "150px"],
    pb: ["48px", "48px", "75px"],
  },
};

export const PsychologySectionTitle = styled(Heading)`
  letter-spacing: -1px;
  color: ${(props) => props.theme.colors.white};
  max-width: 404px;
  text-align: left;
`;

PsychologySectionTitle.defaultProps = {
  sx: {
    font: ["normal normal 600 28px/32px Montserrat;", "normal normal 600 34px/44px Montserrat;"],
  },
};

export const PsychologySectionSubtitle = styled(Paragraph)`
  color: var(--unnamed-color-ffffff);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
`;

PsychologySectionSubtitle.defaultProps = {
  sx: {
    font: ["normal normal 200 14px/20px Montserrat;", "normal normal 400 22px/32px Montserrat;"],
  },
};

export const LiveSection = styled(Box)`
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`;

LiveSection.defaultProps = {
  sx: {
    paddingBottom: ["43px", "43px", "65px"]
  }
}

export const LiveSectionImage = styled(Flex)`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  max-width: 800px;
`;

export const LiveSectionLogoAndMegaTitle = styled(Flex)`
  align-items: center;
  gap: 20px;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const LiveSectionTitle = styled(Heading)`
  letter-spacing: -1px;
  color: ${(props) => props.theme.colors.black};
  opacity: 1;
`;

LiveSectionTitle.defaultProps = {
  sx: {
    font: ["normal normal 600 28px/32px Montserrat;", "normal normal 600 40px/50px Montserrat;"],
    textAlign: ["left", "left", "center"],
    marginTop: [0, 0, "67px"]
  }
};

export const LiveSectionText = styled(Paragraph)`
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.black};
  opacity: 1;
  max-width: 1020px;
`;

LiveSectionText.defaultProps = {
  sx: {
    textAlign: ["left", "left", "left"],
    font: ["normal normal 400 14px/20px Montserrat;", "normal normal 400 22px/32px Montserrat;"],
    margin: ["25px 0 35px 0", "25px 0 35px 0", "35px auto 80px auto"]
  }
};

export const VideoSection = styled(Box)``;

export const VideoSectionText = styled(Flex)`
  background-color: ${(props) => props.theme.colors.primary100};
  color: ${(props) => props.theme.colors.white};
  text-align: center
`;

VideoSectionText.defaultProps = {
  sx: {
    font: ["normal normal 400 14px/20px Montserrat;", "normal normal 400 22px/32px Montserrat;"],
    padding: ["34px 30px 60px 30px", "34px 30px 60px 30px", "65px 0 101px 0"]
  },
};

export const EventsSection = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
`;

EventsSection.defaultProps = {
  sx: {
    padding: ["0", "0", "179px 150px 160px 150px"]
  },
};

export const EventsSectionTitle = styled(Heading)`
  color: ${(props) => props.theme.colors.black};
  opacity: 1;
  text-align: center;
`;

EventsSectionTitle.defaultProps = {
  sx: {
    font: ["normal normal 600 28px/32px Montserrat;", "normal normal 600 40px/40px Montserrat;"],
    marginBottom: ["24px", "24px", "38px"]
  },
};

export const EventsSectionText = styled(Paragraph)`
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  max-width: 487px;
`;

EventsSectionText.defaultProps = {
  sx: {
    font: ["normal normal 400 14px/20px Montserrat;", "normal normal 400 22px/32px Montserrat;"],
    padding: ["0 30px", "0 30px", 0],
    marginBottom: ["24px", "24px", "38px"]
  },
};

export const EventsSectionTextAppendix = styled(Paragraph)`
  color: ${(props) => props.theme.colors.black};
  text-align: center;
`;

EventsSectionTextAppendix.defaultProps = {
  sx: {
    font: ["normal normal 700 18px/40px Montserrat;", "normal normal 700 18px/32px Montserrat;"],
    marginBottom: ["46px", "46px", 0]
  },
};


