import React from "react";
import { Button, Flex } from "theme-ui";
import { Facebook, Instagram, Linkedin, Twitter } from "../../assets/icons";
import { IWILogo } from "../../assets/images";
import { FooterCopyrightText, FooterLink, FooterSection, FooterSectionTitle, FotterSectionContent } from "./styled";
import { BoxedContent } from "../landing";

export default function Footer() {
  return (
    <FooterSection>
      <BoxedContent sx={{ flexDirection: "column" }}>
        <FotterSectionContent>
          <Flex sx={{
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ["80px", "80px", "245px"],
            width: "100%"
          }}>
            <Flex sx={{ width: ["140px", "140px", "93px"], height: ["80px", "80px", "53px"] }}>
              <IWILogo color={"white"} width={"100%"} height={"100%"} />
            </Flex>
            <FooterSectionTitle>
              <Flex
                sx={{
                  flexDirection: ["column", "column", "row"],
                  gap: [0, 0, "8px"],
                  flexWrap: "wrap",
                }}
              >
                Join IWI community
              </Flex>
            </FooterSectionTitle>

            <Flex sx={{
              mt: ["78px", "78px", 0],
              ml: [0, 0, "64px"],
              gap: "40px",
              justifyContent: "center"
            }}>
              <Button variant="transparent" onClick={() => window.open("https://www.facebook.com/joiniwi/", "_blank")}>
                <Facebook width={"45px"} height={"45px"}></Facebook>
              </Button>
              <Button variant="transparent" onClick={() => window.open("https://www.instagram.com/iwiapp/", "_blank")}>
                <Instagram width={"45px"} height={"45px"}></Instagram>
              </Button>
              <Button variant="transparent" onClick={() => window.open("https://twitter.com/iwiapp", "_blank")}>
                <Twitter width={"45px"} height={"45px"}></Twitter>
              </Button>

              <Button
                variant="transparent"
                onClick={() => window.open("https://www.linkedin.com/company/iwiapp/", "_blank")}
              >
                <Linkedin width={"53.22px"} height={"53.22px"}></Linkedin>
              </Button>
            </Flex>

          </Flex>

          <Flex
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: ["column", "column", "row"],
              mt: [0, 0, "64px"],
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", "column", "row"],
                gap: ["8px", "8px", "16px"],
              }}
            >
              <FooterLink href="mailto:info@iwi-app.com">Contact</FooterLink>

              <FooterLink href="/cookie-policy">Cookie Policy</FooterLink>
              <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
            </Flex>

            <Flex sx={{ justifyContent: "center", mt: ["64px", "64px", 0] }}>
              <FooterCopyrightText>© 2023 IWI App, All Rights Reserved</FooterCopyrightText>
            </Flex>
          </Flex>
        </FotterSectionContent>
      </BoxedContent>
    </FooterSection>
  );
}
