import styled from "@emotion/styled";
import React from "react";

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  fieldError?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  position: relative;

  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.white};
  padding-inline: 34px;
  padding-block: 10px;
  border-radius: 30px;

  ${(props) =>
    props.disabled &&
    `
		cursor: not-allowed;
		border:  1px  solid ${props.theme.colors.white}
	`}

  &:hover {
    cursor: pointer;
    margin: 0;
    outline: none;
    border: ${(props) => ` 1px solid ${props.theme.colors.white}`};
  }
  &:focus,
  &:focus-within {
    border: ${(props) => ` 1px solid ${props.theme.colors.white}`};
    outline: 0;
  }
  &:active {
    outline: none;
    margin: 0;
    border: ${(props) => ` 1px solid ${props.theme.colors.white}`};
  }
`;

const TextInputStyled = styled.input<TextInputProps>`
  flex: 1;
  text-align: center;
  font: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  &::placeholder {
    color: ${(props) => props.theme.colors.white};
    opacity: 0.8;
  }
  border: 0;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  color: ${(props) => props.theme.colors.white};
  background: transparent;
`;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { children, ...rest } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => inputRef?.current?.focus();

  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  return (
    <Container disabled={props.disabled} onClick={handleClick}>
      <TextInputStyled {...rest} ref={inputRef}>
        {children}
      </TextInputStyled>
    </Container>
  );
});

export default TextInput;
