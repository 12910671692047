import * as React from "react";

function Facebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Group 863"
      xmlns="http://www.w3.org/2000/svg"
      width={35.904}
      height={35.686}
      viewBox="0 0 35.904 35.686"
      {...props}
    >
      <path
        data-name="Path 439"
        d="M1602.9-224.048A17.952 17.952 0 001584.952-242 17.952 17.952 0 001567-224.048a17.957 17.957 0 0015.147 17.734v-12.545h-4.558v-5.189h4.558V-228c0-4.5 2.68-6.984 6.781-6.984a27.6 27.6 0 014.019.351v4.418h-2.264a2.6 2.6 0 00-2.926 2.8v3.367h4.979l-.8 5.189h-4.183v12.545a17.956 17.956 0 0015.147-17.734z"
        transform="translate(-1567 242)"
        fill="#fff"
      />
    </svg>
  );
}

export default Facebook;
