import styled from "@emotion/styled";
import { keyframes } from '@emotion/react'
import { Box } from "theme-ui";


const FadeInOut = keyframes`
    0% {
        opacity: 1;
    }
    17% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    92% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const LandingAppImageWrapper = styled(Box)`
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        background: linear-gradient(to bottom, ${(props) => props.theme.colors.primary100} 60%, ${(props) => props.theme.colors.white} 40%);
    };
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    & > img {
        position: absolute;
        top: 0;
        animation: ${FadeInOut} 17400ms ease infinite;
        max-width: 100%;

        &:nth-of-type(1) {
            animation-delay: 13050ms;
        }
        
        &:nth-of-type(2) {
            animation-delay: 8700ms;
        }
        
        &:nth-of-type(3) {
            animation-delay: 4350ms;
        }
        
        &:nth-of-type(4) {
            animation-delay: 0ms;
        }
    }
`;

LandingAppImageWrapper.defaultProps = {
    sx: {
        minHeight: ["624px", "624px", "816px"]
    }
}

