import * as React from "react";

function LinkedIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Group 1772"
      xmlns="http://www.w3.org/2000/svg"
      width={41.909}
      height={35.686}
      viewBox="0 0 41.909 35.686"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path data-name="Rectangle 1721" fill="none" d="M0 0H41.909V35.686H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1771" clipPath="url(#a)" fill="#fff">
        <path
          data-name="Path 4284"
          d="M71.847 59.932a.657.657 0 00.663-.65v-.044c0-.492-.3-.727-.906-.727h-.984v2.577h.37v-1.124h.455l.01.014.706 1.109h.4l-.76-1.149zm-.428-.259h-.429V58.8h.544c.281 0 .6.046.6.414 0 .423-.324.457-.719.457"
          transform="translate(-31.995 -26.509)"
        />
        <path
          data-name="Path 4285"
          d="M30.193 30.193h-5.251V21.97c0-1.961-.034-4.485-2.73-4.485-2.735 0-3.153 2.136-3.153 4.342v8.365h-5.251V13.283h5.041v2.311h.071a5.524 5.524 0 014.973-2.731c5.322 0 6.3 3.5 6.3 8.054zM7.884 10.972a3.047 3.047 0 113.046-3.048 3.047 3.047 0 01-3.046 3.048m2.625 19.221H5.253v-16.91h5.256zM32.811 0H2.615A2.586 2.586 0 000 2.556v30.322a2.587 2.587 0 002.615 2.557h30.2a2.593 2.593 0 002.625-2.557V2.554A2.591 2.591 0 0032.811 0"
        />
        <path
          data-name="Path 4286"
          d="M70.181 56.412a2.416 2.416 0 10.047 0h-.047m0 4.556a2.119 2.119 0 112.083-2.154v.036a2.074 2.074 0 01-2.028 2.117h-.055"
          transform="translate(-30.712 -25.559)"
        />
      </g>
    </svg>
  );
}

export default LinkedIn;
