import React from "react";
import { ThemeProvider } from "theme-ui";
import LandingPage from "./pages/landing";
import { theme } from "./theme";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy-policy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
]);

// Add react router if needed
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
