import * as React from "react";

function AppleStore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={154.058} height={49.976} viewBox="0 0 154.058 49.976" {...props}>
      <g id="Group_3726" data-name="Group 3726" transform="translate(0 0)">
        <g id="Group_3721" data-name="Group 3721">
          <g id="Group_3720" data-name="Group 3720">
            <g id="Group_3719" data-name="Group 3719">
              <path id="Path_11745" data-name="Path 11745" d="M10.766,100.528c-.4,0-.787-.005-1.181-.014a16.664,16.664,0,0,1-2.443-.213,7.7,7.7,0,0,1-2.164-.716,7.055,7.055,0,0,1-1.825-1.329,6.956,6.956,0,0,1-1.334-1.824,7.515,7.515,0,0,1-.709-2.165,16.2,16.2,0,0,1-.217-2.45c-.009-.276-.02-1.194-.02-1.194V60.444s.012-.9.02-1.169a16.135,16.135,0,0,1,.216-2.446,7.506,7.506,0,0,1,.711-2.171,7.017,7.017,0,0,1,1.326-1.826A7.237,7.237,0,0,1,4.977,51.5a7.6,7.6,0,0,1,2.16-.711,16.353,16.353,0,0,1,2.451-.214l1.178-.016H145.032l1.193.016a16.163,16.163,0,0,1,2.427.213,7.735,7.735,0,0,1,2.183.716,7.191,7.191,0,0,1,1.819,1.326,7.264,7.264,0,0,1,1.337,1.836,7.483,7.483,0,0,1,.7,2.154,16.716,16.716,0,0,1,.227,2.465c0,.37,0,.767,0,1.163.01.49.01.956.01,1.427V89.213c0,.476,0,.938-.01,1.406,0,.425,0,.814-.005,1.214a16.424,16.424,0,0,1-.223,2.422,7.475,7.475,0,0,1-.705,2.182,7.146,7.146,0,0,1-1.326,1.809,7.074,7.074,0,0,1-1.829,1.337,7.66,7.66,0,0,1-2.179.719,16.452,16.452,0,0,1-2.442.213c-.383.009-.783.014-1.172.014l-1.416,0Z" transform="translate(-0.873 -50.555)" fill="#fff"/>
            </g>
          </g>
        </g>
        <g id="Group_3725" data-name="Group 3725" transform="translate(11.414 9.109)">
          <g id="Group_3723" data-name="Group 3723">
            <g id="Group_3722" data-name="Group 3722">
              <path id="Path_11746" data-name="Path 11746" d="M29.713,71.586a6.724,6.724,0,0,1,3.2-5.641,6.886,6.886,0,0,0-5.422-2.932c-2.281-.239-4.493,1.365-5.656,1.365-1.185,0-2.975-1.34-4.9-1.3a7.219,7.219,0,0,0-6.076,3.707c-2.627,4.549-.668,11.235,1.85,14.912,1.258,1.8,2.731,3.811,4.656,3.74,1.884-.078,2.588-1.2,4.863-1.2,2.254,0,2.915,1.2,4.878,1.156,2.022-.033,3.3-1.808,4.511-3.626a14.88,14.88,0,0,0,2.063-4.2A6.5,6.5,0,0,1,29.713,71.586Z" transform="translate(-9.609 -55.853)"/>
              <path id="Path_11747" data-name="Path 11747" d="M23.2,62.268a6.616,6.616,0,0,0,1.514-4.741,6.73,6.73,0,0,0-4.357,2.254A6.3,6.3,0,0,0,18.8,64.348,5.569,5.569,0,0,0,23.2,62.268Z" transform="translate(-6.803 -57.527)"/>
            </g>
          </g>
          <g id="Group_3724" data-name="Group 3724" transform="translate(32.424 13.096)">
            <path id="Path_11748" data-name="Path 11748" d="M44.716,79.6H38.531l-1.484,4.386h-2.62l5.857-16.224h2.721l5.858,16.224H46.2Zm-5.543-2.024h4.9l-2.417-7.116h-.068Z" transform="translate(-34.427 -67.488)"/>
            <path id="Path_11749" data-name="Path 11749" d="M57.773,77.066c0,3.676-1.968,6.037-4.936,6.037a4.01,4.01,0,0,1-3.722-2.069h-.056v5.86H46.631V71.151h2.35v1.968h.044a4.2,4.2,0,0,1,3.767-2.092C55.795,71.027,57.773,73.4,57.773,77.066Zm-2.5,0c0-2.395-1.237-3.969-3.126-3.969-1.854,0-3.1,1.608-3.1,3.969,0,2.384,1.249,3.98,3.1,3.98C54.04,81.045,55.277,79.483,55.277,77.066Z" transform="translate(-30.687 -66.486)"/>
            <path id="Path_11750" data-name="Path 11750" d="M67.739,77.066c0,3.676-1.969,6.037-4.937,6.037a4.01,4.01,0,0,1-3.722-2.069h-.056v5.86H56.6V71.151h2.35v1.968h.044a4.2,4.2,0,0,1,3.767-2.092C65.758,71.027,67.739,73.4,67.739,77.066Zm-2.5,0c0-2.395-1.237-3.969-3.126-3.969-1.855,0-3.1,1.608-3.1,3.969,0,2.384,1.248,3.98,3.1,3.98C64.005,81.045,65.242,79.483,65.242,77.066Z" transform="translate(-27.632 -66.486)"/>
            <path id="Path_11751" data-name="Path 11751" d="M72.292,79.525c.18,1.608,1.743,2.665,3.879,2.665,2.047,0,3.52-1.057,3.52-2.507,0-1.259-.888-2.013-2.991-2.531l-2.1-.506c-2.979-.72-4.362-2.114-4.362-4.374,0-2.8,2.439-4.722,5.9-4.722,3.43,0,5.78,1.922,5.858,4.722H79.544c-.146-1.619-1.484-2.6-3.441-2.6s-3.294.988-3.294,2.427c0,1.147.854,1.823,2.946,2.339l1.787.439c3.329.788,4.711,2.124,4.711,4.5,0,3.036-2.417,4.937-6.262,4.937-3.6,0-6.028-1.857-6.185-4.791Z" transform="translate(-23.583 -67.551)"/>
            <path id="Path_11752" data-name="Path 11752" d="M84.322,68.979v2.8h2.25V73.7h-2.25v6.521c0,1.013.451,1.485,1.44,1.485a7.8,7.8,0,0,0,.8-.056v1.911a6.756,6.756,0,0,1-1.348.111c-2.395,0-3.329-.9-3.329-3.193V73.7H80.163V71.779h1.719v-2.8Z" transform="translate(-20.41 -67.113)"/>
            <path id="Path_11753" data-name="Path 11753" d="M86.064,77.071c0-3.721,2.192-6.06,5.61-6.06s5.611,2.339,5.611,6.06-2.17,6.061-5.611,6.061S86.064,80.8,86.064,77.071Zm8.747,0c0-2.553-1.169-4.059-3.137-4.059s-3.137,1.518-3.137,4.059c0,2.563,1.169,4.059,3.137,4.059S94.811,79.634,94.811,77.071Z" transform="translate(-18.601 -66.491)"/>
            <path id="Path_11754" data-name="Path 11754" d="M96.185,71.151H98.5v2.013h.056a2.821,2.821,0,0,1,2.844-2.137,3.678,3.678,0,0,1,.832.091v2.271a3.385,3.385,0,0,0-1.091-.146,2.447,2.447,0,0,0-2.531,2.721V82.98H96.185Z" transform="translate(-15.499 -66.486)"/>
            <path id="Path_11755" data-name="Path 11755" d="M111.886,79.511c-.327,2.147-2.417,3.62-5.093,3.62-3.441,0-5.577-2.306-5.577-6s2.148-6.116,5.475-6.116c3.273,0,5.33,2.247,5.33,5.833v.832h-8.355v.146a3.082,3.082,0,0,0,3.183,3.351,2.677,2.677,0,0,0,2.732-1.663Zm-8.207-3.531h5.913a2.844,2.844,0,0,0-2.9-3A2.994,2.994,0,0,0,103.679,75.98Z" transform="translate(-13.957 -66.491)"/>
          </g>
        </g>
      </g>
      <g id="Group_3728" data-name="Group 3728" transform="translate(45.463 9.88)">
        <g id="Group_3727" data-name="Group 3727">
          <path id="Path_11756" data-name="Path 11756" d="M38.486,58.411c2.312,0,3.669,1.42,3.669,3.872,0,2.491-1.346,3.922-3.669,3.922H35.671V58.411Zm-1.6,6.693h1.47c1.63,0,2.57-1.016,2.57-2.8,0-1.762-.955-2.788-2.57-2.788h-1.47Z" transform="translate(-35.671 -58.027)"/>
          <path id="Path_11757" data-name="Path 11757" d="M41.681,62.84c0-1.9,1.058-3.053,2.776-3.053s2.772,1.156,2.772,3.053S46.175,65.9,44.457,65.9,41.681,64.746,41.681,62.84Zm4.355,0c0-1.275-.574-2.021-1.578-2.021s-1.577.746-1.577,2.021.567,2.025,1.577,2.025S46.036,64.121,46.036,62.84Z" transform="translate(-33.829 -57.605)"/>
          <path id="Path_11758" data-name="Path 11758" d="M53.107,65.757H51.9l-1.215-4.332h-.093l-1.21,4.332H48.192l-1.623-5.883h1.178L48.8,64.363h.088l1.21-4.489h1.113l1.21,4.489h.091l1.049-4.489h1.161Z" transform="translate(-32.331 -57.578)"/>
          <path id="Path_11759" data-name="Path 11759" d="M53.854,59.9h1.117v.935h.086a1.761,1.761,0,0,1,1.756-1.049,1.914,1.914,0,0,1,2.037,2.188v3.808H57.689V62.267c0-.945-.41-1.415-1.27-1.415a1.349,1.349,0,0,0-1.4,1.491v3.441h-1.16Z" transform="translate(-30.098 -57.605)"/>
          <path id="Path_11760" data-name="Path 11760" d="M59.094,58.117h1.16V66.3h-1.16Z" transform="translate(-28.492 -58.117)"/>
          <path id="Path_11761" data-name="Path 11761" d="M61.218,62.84c0-1.9,1.058-3.053,2.776-3.053s2.772,1.156,2.772,3.053S65.712,65.9,63.994,65.9,61.218,64.746,61.218,62.84Zm4.355,0c0-1.275-.574-2.021-1.578-2.021s-1.577.746-1.577,2.021.567,2.025,1.577,2.025S65.573,64.121,65.573,62.84Z" transform="translate(-27.841 -57.605)"/>
          <path id="Path_11762" data-name="Path 11762" d="M66.4,64.121c0-1.06.788-1.67,2.188-1.756l1.593-.093v-.507c0-.622-.41-.972-1.2-.972-.649,0-1.1.238-1.227.653H66.628c.119-1.01,1.069-1.659,2.4-1.659,1.474,0,2.306.736,2.306,1.978v4.019H70.221v-.826h-.093a1.979,1.979,0,0,1-1.766.924A1.777,1.777,0,0,1,66.4,64.121Zm3.781-.5v-.491l-1.436.091c-.81.054-1.178.329-1.178.848s.46.839,1.091.839A1.387,1.387,0,0,0,70.182,63.618Z" transform="translate(-26.253 -57.605)"/>
          <path id="Path_11763" data-name="Path 11763" d="M71.348,63.352c0-1.859.956-3.036,2.442-3.036a1.937,1.937,0,0,1,1.8,1.032h.088V58.117h1.16V66.3H75.73v-.929h-.093a2.042,2.042,0,0,1-1.847,1.027C72.294,66.394,71.348,65.215,71.348,63.352Zm1.2,0c0,1.248.588,2,1.572,2s1.583-.762,1.583-1.994-.611-2-1.583-2S72.547,62.115,72.547,63.352Z" transform="translate(-24.736 -58.117)"/>
          <path id="Path_11764" data-name="Path 11764" d="M79.23,62.84c0-1.9,1.06-3.053,2.776-3.053s2.772,1.156,2.772,3.053S83.724,65.9,82.006,65.9,79.23,64.746,79.23,62.84Zm4.355,0c0-1.275-.572-2.021-1.578-2.021s-1.577.746-1.577,2.021.568,2.025,1.577,2.025S83.585,64.121,83.585,62.84Z" transform="translate(-22.321 -57.605)"/>
          <path id="Path_11765" data-name="Path 11765" d="M84.669,59.9h1.118v.935h.086a1.761,1.761,0,0,1,1.756-1.049,1.914,1.914,0,0,1,2.037,2.188v3.808H88.5V62.267c0-.945-.41-1.415-1.27-1.415a1.349,1.349,0,0,0-1.4,1.491v3.441H84.669Z" transform="translate(-20.654 -57.605)"/>
          <path id="Path_11766" data-name="Path 11766" d="M94.007,58.753v1.492h1.275v.977H94.007v3.026c0,.617.253.886.832.886a3.939,3.939,0,0,0,.443-.026v.967a3.885,3.885,0,0,1-.632.059c-1.291,0-1.8-.453-1.8-1.589V61.222h-.934v-.977h.934V58.753Z" transform="translate(-18.434 -57.922)"/>
          <path id="Path_11767" data-name="Path 11767" d="M95.705,58.117h1.15v3.241h.093A1.81,1.81,0,0,1,98.741,60.3a1.938,1.938,0,0,1,2.026,2.194v3.8H99.6V62.784c0-.939-.438-1.415-1.258-1.415a1.374,1.374,0,0,0-1.482,1.491V66.3h-1.16Z" transform="translate(-17.271 -58.117)"/>
          <path id="Path_11768" data-name="Path 11768" d="M106,64.2a2.388,2.388,0,0,1-2.549,1.7c-1.685,0-2.717-1.156-2.717-3.036s1.054-3.074,2.712-3.074c1.637,0,2.625,1.118,2.625,2.967v.405h-4.155v.064a1.556,1.556,0,0,0,1.566,1.685,1.411,1.411,0,0,0,1.4-.712Zm-4.084-1.9h2.972a1.42,1.42,0,0,0-1.449-1.523A1.5,1.5,0,0,0,101.912,62.3Z" transform="translate(-15.731 -57.605)"/>
        </g>
      </g>
    </svg>
  );
}

export default AppleStore;
